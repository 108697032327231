.nav-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  max-width: 100%;
  border-bottom: solid black 1px ;
  padding: 0 24px;
}
.navbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo-container{
  width: 150px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding-right: 10px;
  text-decoration: none;
}
.landing-logo {
  text-decoration: none;
}

#landing-logo img {
  height: 35px;
}

#github-logo{
  width: 29px;
  height: 29px;
  margin-right: 5px;
}

.github-links{
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.right-nav-container{
  display: flex;
  align-items: center;
  max-height: 100%;
  height: 100%;
  font-size: 13px;
  font-weight: 700;
  right: 10%;
}

.login{
  background-color: #f50;
  border-color: #f50;
  color: #fff;
  display: inline-block;
  position: relative;
  height: 26px;
  margin: 0;
  padding: 2px 11px 2px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  font-weight: 100;
  text-align: center;
  vertical-align: initial;
  box-sizing: border-box;
  text-decoration: none;
}


.signup{
  background-color: #f50;
  border-color: #f50;
  color: #fff;
  display: inline-block;
  position: relative;
  height: 26px;
  margin: 0;
  padding: 2px 11px 2px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  font-weight: 100;
  text-align: center;
  vertical-align: initial;
  box-sizing: border-box;
  text-decoration: none;
}


.left-container{
  display: flex;
  align-items: center;
  height: 100%;
  width: 800px;
}
