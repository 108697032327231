.landing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.splash-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.splash-main-header {
  text-align: center;
  margin-top: 20px;
}

.splash-main-header p {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.splash-sub-header {
  text-align: center;
  margin-top: 10px;
}

.splash-sub-header p {
  font-size: 18px;
  color: #555;
}

.song-containers {
  display: grid;
  grid-template-columns: repeat(5,250px);
  grid-template-rows: 300px 300px;
  place-items: center;
  margin-bottom: 50px;
}
