.song-details-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.song-details-page h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.comment-form {
  margin-top: 20px;
}

.error-list {
  list-style: none;
  padding: 0;
  color: red;
}

.comment-label {
  display: block;
  margin-bottom: 10px;
}

.comment-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.comment-submit {
  background-color: #f50;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
}

.comments-heading {
  margin-top: 20px;
  font-size: 20px;
}

.comments-list {
  padding: 0;
  margin-bottom: 120px;
}

.comment-item {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
}

.comment-content {
  font-size: 14px;
}

.comment-username {
  font-weight: bold;
}

.comment-text {
  margin-top: 4px;
}

.comment-buttons {
  display: flex;
  padding-top: 8px;
}

.edit-modal-button,
.delete-comment-button {
  margin-left: 3px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.edit-modal-button img,
.delete-comment-button img {
  width: 20px;
  height: 20px;
}

.save-modal-button {
  background-color: #f50;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}
