.song-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.song-details {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 20px;
}

.song-image {
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  margin-right: 20px;
}

.song-details-text {
  flex-grow: 1;
}

.song-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 5px;
}

.song-description {
  font-size: 1em;
  color: #555;
}
