.edit-song-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.edit-song-form {
  display: flex;
  flex-direction: column;
}

.edit-song-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.input-field {
  margin-bottom: 20px;
}

.input-label {
  font-size: 16px;
  margin-bottom: 5px;
}

.input-text,
.input-textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.submit-button {
  background-color: #f50;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}

.submit-button:hover {
  background-color: #f50;
}
