.sign-up-container {
  background-color: #fff;
  box-shadow: 0,0,18px,0,rgba(0,0,0,.75);
  display: grid;
  grid-template-rows: .2fr 1.2fr;
  height: 27%;
  left: 50%;
  padding: 20px;
  top: 20%;
  width: 200px;
}

.sign-up-container input {
  margin-bottom: 15px;
}

.sign-up-container h3 {
  margin-left: 50px;
}
