.manage-songs-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.manage-songs {
  text-align: center;
  margin-bottom: 120px;
}

.manage-songs-heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.no-songs {
  margin-top: 150px;
}

.upload-button,
.edit-button {
  background-color: #f50;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.song-card-container {
  margin-bottom: 20px;
  margin-top: 220px;
}
