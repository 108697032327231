.song-card {
  display: block;
  text-decoration: none;
  overflow: hidden;
  margin-bottom: 20px;
}


.song-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.song-card-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
}

.song-card-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-bottom: 1px solid #ddd;
}
