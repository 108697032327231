body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout-container {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  position: fixed;
}

.Footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}
