.create-song-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.error-list {
  list-style: none;
  padding: 0;
  color: red;
}

.create-song-label {
  display: block;
  margin-bottom: 10px;
}

.create-song-input,
.create-song-file-input,
.create-song-textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.create-song-button {
  background-color: #f50;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
}
