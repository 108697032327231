.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f8f8;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.rhap_container {
  gap: 20px;
  width: 90%;
  margin: 0 auto;
}

.rhap_progress-container {
  flex: 1;
  height: 4px;
  margin: 0 16px;
}

.rhap_progress-bar {
  height: 100%;
  background-color: #333;
}

.rhap_time {
  font-size: 14px;
  color: #333;
}
